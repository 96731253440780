<template>
  <b-modal
    id="patient-modal"
    :visible="show"
    ref="patient-modal"
    title="Thêm khách hàng"
    centered
    size="xl"
    cancel-title="Hủy"
    ok-title="Lưu"
    ok-variant="success"
    :ok-disabled="disableBtnSubmit"
    @hidden="resetModal"
    @ok="submit"
  >
    <b-overlay :show="loading">
      <b-container fluid class="px-0">
        <b-row class="mb-2">
          <b-col>
            <!-- <b-row>
              <b-col class="py-0">
                <label for="stảtDate" class="mb-0">Ngày bắt đầu</label>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col>
                <label for="startDate" class="mb-0">Ngày bắt đầu</label>
                <date-picker
                  placeholder="Từ ngày"
                  name="startDate"
                  input-class="form-control"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="format"
                  class="mt-4"
                  :disabled-date="disabledBeforeFromDate"
                  v-model="filters.startDate"
                  @change="selectDate('start', $event)"
                >
                </date-picker>
              </b-col>
              <b-col>
                <label for="startDate" class="mb-0">Ngày kết thúc</label>
                <date-picker
                  placeholder="Đến ngày"
                  name="endDate"
                  input-class="form-control"
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="format"
                  class="mt-4"
                  :disabled-date="disabledBeforeToDate"
                  v-model="filters.endDate"
                  @change="selectDate('end', $event)"
                >
                </date-picker>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <label for="startDate" class="mb-0">Khách hàng</label>
                <basic-input
                  name="name"
                  placeholder="Nhập tên khách hàng hoặc số điện thoại"
                  :value.sync="filters.searchText"
                  :debounce="2000"
                  class="mt-4"
                  @v-modal:update="searchDebounce"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <TablePatient
          type="add-patient"
          :show-paging="true"
          :show-select="true"
          :show-actions="false"
          :paging="paging"
          :patients="patients"
          @search="search"
          @select="select"
          @sortBy="sortRequest"
          @open-modal-mapping="openModalMapping"
        />
      </b-container>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_SELECTED_PATIENTS,
  GET_PATIENTS,
} from '@/core/services/store/coach.module';
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('coach');
import { uniqBy } from 'lodash';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'ModalAddPatient',
  components: {
    TablePatient: () => import('./TablePatient.vue'),
    'date-picker': DatePicker,
  },
  props: {
    maxMember: {
      type: [String, Number],
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      selectedPatients: [],
      filters: {
        searchText: null,
        startDate: null,
        endDate: null,
      },
      paging: {
        page: 1,
        pageSize: 10,
        total: 10,
      },
      startDate: null,
      endDate: null,
      sort: {
        by: null,
        order: null,
      },
    };
  },
  computed: {
    ...mapState({
      patients: 'patients',
      selectedPatientsStore: 'selectedPatients',
    }),
    searchParams() {
      return {
        searchText: this.filters.searchText,
        orderByExtend: this.sort.by
          ? `${this.sort.by} ${this.sort.order}`
          : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        startDate: this.startDate,
        endDate: this.endDate,
        mappingGroupId: this.$route.params.id,
      };
    },
    disableBtnSubmit() {
      return (
        this.selectedPatients.length === 0 ||
        this.selectedPatients.length > Number(this.maxMember)
      );
    },
  },
  watch: {
    'paging.page': function () {
      this.search();
    },
    'paging.pageSize': function () {
      this.search();
    },
  },
  created() {
    this.setupData();
  },
  methods: {
    ...mapMutations({ SET_SELECTED_PATIENTS }),
    ...mapActions({ GET_PATIENTS }),
    async setupData() {
      this.search();
    },
    resetModal() {
      this.$emit('reset');
    },
    async search() {
      this.loading = true;
      const { meta, error } = await this.GET_PATIENTS(this.searchParams);
      if (!error) {
        this.paging.total = meta.total;
      }
      this.loading = false;
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.search();
    },
    submit() {
      if (!this.selectedPatients.length) return;
      this.selectedPatients = [...this.selectedPatients].map((el) => ({
        ...el,
        isNew: true,
      }));
      this.SET_SELECTED_PATIENTS(
        uniqBy([...this.selectedPatientsStore, ...this.selectedPatients], 'id'),
      );
    },
    select(params) {
      this.selectedPatients = params;
    },
    searchDebounce(value) {
      this.filters.searchText = value;
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.search();
    },
    selectDate(type, date) {
      if (date) {
        if (type === 'start') {
          this.startDate = this.$moment(date, 'DD/MM/YYYY')
            .startOf('date')
            .format('X');
        } else {
          this.endDate = this.$moment(date, 'DD/MM/YYYY')
            .endOf('date')
            .format('X');
        }
      } else {
        if (type === 'start') {
          this.startDate = null;
        } else {
          this.endDate = null;
        }
      }
      this.resetPaging();
    },
    disabledBeforeToDate(date) {
      if (this.filters.startDate) {
        const newDate = this.convertToISODate(this.filters.startDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date < deadline;
      }
    },
    disabledBeforeFromDate(date) {
      if (this.filters.endDate) {
        const newDate = this.convertToISODate(this.filters.endDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date > deadline;
      }
    },
    openModalMapping(payload) {
      this.$emit('open-modal-mapping', payload);
    },
  },
};
</script>
<style lang=""></style>
